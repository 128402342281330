.ant-rate {
  font-size: 15px;
}

.ant-rate-star-full {
  .anticon-star {
    @apply dark:text-yellow-500;
  }
}

.ant-rate-star-half {
  .ant-rate-star-first {
    .anticon-star {
      @apply dark:text-yellow-500;
    }
  }
  .ant-rate-star-second {
    .anticon-star {
      @apply dark:text-gray-600;
    }
  }
}

.ant-rate-star-zero {
  .anticon-star {
    @apply dark:text-gray-600;
  }
}
