.header {
    @apply z-40 sticky top-0 h-20 inline-flex items-center w-full;
}

.header-designer {
    @apply flex w-full items-center justify-between border-b border-gray-200 dark:border-gray-700 bg-white px-6 py-4 dark:bg-gray-900;
}

.header-wrapper {
    @apply flex relative items-center justify-between w-full;
}

.header-action {
    @apply flex items-center;
}

.header-action-item {
    @apply cursor-pointer rounded-full pl-3;
}

.header-action-item-hoverable {
    @apply
    hover:bg-black
    hover:bg-opacity-5
    hover:text-gray-900
    dark:hover:bg-black
    dark:hover:text-gray-100
    dark:hover:bg-opacity-40
    transition-colors
    duration-300
    ease-in-out;
}
