.dropdown {
  @apply relative inline-block;

  .dropdown-count {
    @apply ml-1.5 rounded bg-gray-200 dark:bg-gray-700 py-0.5 px-1.5 text-xs font-semibold tabular-nums text-gray-700 dark:text-gray-400;
  }

  .dropdown-arrow {
    svg-icon {
      @apply transition ease-in-out duration-150;

      &.clicked {
        @apply transform rotate-180;
      }
    }
  }
}

.dropdown-menu {
  min-width: 160px;
  width: max-content;
  @apply rounded-md shadow-lg bg-white dark:bg-gray-800 dark:shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none z-30 #{!important};

  .dropdown-item {
    @apply dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white py-1.5 rounded-md;

    &.ant-dropdown-menu-item-selected {
      @apply dark:bg-gray-700 dark:text-white;
    }
  }

  &.lg {
    max-width: 430px;
    .dropdown-item {
      @apply relative flex gap-x-6 rounded-md p-4 hover:bg-gray-50 dark:hover:bg-gray-600;

      .dropdown-item-icon {
        @apply mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 dark:bg-gray-600;

        .drop-icon {
          @apply text-gray-600 dark:text-gray-50;
        }
      }

      .link {
        @apply text-gray-900 hover:text-indigo-600 dark:text-white dark:hover:text-indigo-400;
      }
    }
  }
}

.dropdown-toggle-default {
    @apply cursor-pointer p-2 inline-block;

    &.dropdown-toggle-disabled {
        @apply opacity-60;
    }
}

.dropdown-submenu {
    @apply left-full right-full top-0 origin-top;
}

.dropdown-submenu-item {
    @apply justify-between;
}

.dropdown-toggle-disabled {
    @apply cursor-not-allowed;
}
