.simple-layout-content {
  @apply mt-8 grid max-w-3xl grid-cols-1 gap-6 lg:max-w-6xl lg:grid-flow-col-dense lg:grid-cols-7;

  .left-content {
    @apply space-y-6 lg:col-span-4 lg:col-start-1;
  }

  .right-content {
    @apply lg:col-span-3 lg:col-start-5;
  }

}

.simple-layout-content-xl {
  @apply mt-8 grid grid-cols-1 gap-6 lg:grid-flow-col-dense lg:grid-cols-8;

  .left-content-xl {
    @apply space-y-6 lg:col-span-3 lg:col-start-1;
  }

  .right-content-xl {
    @apply lg:col-span-5 lg:col-start-4;
  }
}

.min-h {
  min-height: calc(100vh - 144px);
}
