.form-item {
  @apply sm:mb-7 md:mb-0 lg:mb-0 relative;

  &.horizontal {
    grid-template-rows: 100%;
    @apply grid md:grid-cols-12 auto-cols-fr gap-4 py-5 border-t border-gray-100 dark:border-gray-700 items-center;

    .form-label {
      @apply justify-start text-gray-800 dark:text-gray-200;
    }

    .input-label {
      @apply col-span-5 h-full;
    }

    .input-wrapper {
      @apply flex flex-col col-span-7;
    }
  }

  &.vertical {
    @apply flex flex-col;
  }

  &.inline {
    @apply md:inline-flex mr-3 ml-3;
  }

  &.auth-form {
    @apply mb-7;
  }

}

.form-label {
  @apply flex items-center font-semibold text-gray-800 dark:text-gray-200 tracking-[-0.01em];

  &.invalid {
    @apply text-red-500;
  }

  &.required:after {
    content:" *";
    padding-left: .25rem;
    @apply text-red-600;
  }
}

.form-explain {
  @apply text-red-500 absolute;
}

.form-container {
  &.inline {
    @apply md:flex items-center;
  }
}
