.ant-collapse {
  @apply bg-transparent;
}

.ant-collapse-item {
  margin-bottom: 10px;
}

.ant-collapse-header {
  @apply font-semibold flex items-center rounded-lg bg-gray-100 px-2 hover:bg-gray-50 focus:outline-none dark:text-white dark:bg-gray-700 dark:hover:bg-gray-700 #{!important};
}
