.ant-tabs-tab {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  padding: 12px 0;
  @apply font-sans;
}

.ant-tabs-tab {
  &.ant-tabs-tab-active {
    .ant-tabs-tab-btn, .ant-tabs-tab-btn > a {
      @apply text-gray-700 dark:text-orange-50;
    }
  }
}

.ant-tabs-tab {
  .ant-tabs-tab-btn {
    @apply py-3 px-2 rounded-lg;

    a {
      @apply text-gray-500 dark:text-gray-400 font-sans;
    }
  }
}

.ant-tabs-tab + .ant-tabs-tab {
  margin: 0 0 0 8px;
}

.ant-tabs-bottom > .ant-tabs-nav,
.ant-tabs-bottom > div > .ant-tabs-nav,
.ant-tabs-top > .ant-tabs-nav,
.ant-tabs-top > div > .ant-tabs-nav {
  margin: 0 0 32px;
}

.ant-tabs-tab {
  &:hover {
    .ant-tabs-tab-btn {
      @apply bg-gray-100 dark:text-gray-200 dark:bg-gray-700 rounded-lg;
    }
  }
}

.ant-tabs-tab-btn {
  @apply text-gray-500 dark:text-gray-400 font-sans;
}

.ant-tabs-ink-bar {
  @apply bg-orange-500 dark:bg-orange-400;
}

.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
  @apply dark:bg-gray-700 border-gray-100 dark:border-gray-700;
}

.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-bottom > div > .ant-tabs-nav::before,
.ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before {
  @apply border-gray-100 dark:border-gray-700;
}

.ant-tabs-extra-content {
  position: absolute;
  right: 0;
}

.inventory-tabset {
  .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab,
  .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
    @apply dark:bg-gray-700 dark:border-gray-700;
  }
}
