.upload {
  @apply flex max-w-xl justify-center cursor-pointer rounded-lg border border-dashed border-gray-200 dark:border-gray-700 px-6 py-5 h-full w-full hover:border-transparent hover:ring-2 hover:ring-indigo-700 dark:hover:ring-indigo-500;

  label {
    @apply relative cursor-pointer p-2;
  }

  svg-icon {
    @apply mx-auto text-gray-500 dark:text-gray-400;
  }
}

.ant-upload {
  @apply dark:bg-transparent dark:border-white/10 #{!important};
}

.ant-upload-drag-container {
  @apply dark:text-white;
}

.ant-upload-text {
  @apply text-gray-500 dark:text-gray-400 #{!important};
}

.ant-upload-drag-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.upload-button {
  @apply font-semibold focus:outline-none whitespace-nowrap w-full flex items-center justify-center;

  label {
    display: flex;
    align-items: center;
    @apply rounded bg-indigo-50 dark:bg-indigo-500 dark:bg-opacity-20 hover:bg-indigo-100 dark:hover:bg-indigo-500 dark:hover:bg-opacity-30 active:bg-indigo-200 dark:active:bg-indigo-500 dark:active:bg-opacity-40 text-indigo-600 dark:text-indigo-50;

    &.xs {
      @apply h-7 px-3 py-1 text-xs;
    }

    &.sm {
      @apply h-9 px-3 py-2 text-sm;
    }

    &.md {
      @apply h-11 px-4 py-2;
    }
  }
}

.upload-square-label {
  @apply h-16 w-16 flex-shrink-0 h-16 cursor-pointer rounded-lg border border-dashed border-gray-400 dark:border-gray-700 bg-white dark:bg-gray-800 bg-center bg-no-repeat text-center hover:border-transparent hover:ring-2 hover:ring-indigo-500 dark:hover:border-transparent dark:hover:bg-gray-900;
}
