.ant-table-tbody > tr > td {
  @apply bg-white dark:bg-gray-900 dark:text-white dark:border-gray-700;
}

.ant-table .ant-table-thead > tr > th {
  @apply bg-white dark:bg-gray-900 dark:text-gray-400 dark:border-gray-700;
}

.ant-table-tbody > tr.ant-table-row:hover > td,
.ant-table-tbody > tr > td.ant-table-cell-row-hover {
  @apply dark:bg-gray-700 dark:text-gray-50 dark:border-gray-700;
}

.ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  @apply dark:bg-gray-700;
}

.ant-table-row {
  &:hover {
    cursor: pointer;
  }
}

.ant-table-tbody > tr.ant-table-placeholder:hover > td {
  @apply dark:bg-gray-900 dark:text-white cursor-pointer;
}

.ant-table {
  font-family: inherit !important;
  @apply bg-white dark:bg-gray-800;

  .ant-table-thead > tr > th {
    @apply text-gray-500 dark:text-gray-400 font-medium;
  }

  .ant-table-title {
    @apply dark:text-white font-sans leading-6;
  }

  .ant-table-content {
    @apply border-t border-gray-200 dark:border-gray-700;

    .ant-table-thead {
      tr {
        th {
          @apply font-medium font-sans text-gray-500 dark:text-gray-400 border-gray-200 dark:border-gray-700;
        }
      }
    }

    .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
    .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
    .ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td,
    .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
      background: unset; //Change the existing color to `unset`
    }

    .ant-table-tbody > tr > td {
      @apply border-gray-200 dark:border-gray-600;
    }

    .ant-table-tbody {
      .ant-table-cell {
        @apply border-gray-200 dark:border-gray-700 #{!important};
      }

      tr.ant-table-expanded-row {
        td.nz-disable-td.ant-table-cell {
          @apply dark:bg-gray-800;
        }
      }

      tr.ant-table-row {
        background-color: inherit; //Change the existing color to `unset`
        @apply hover:bg-gray-50 hover:dark:bg-gray-700 text-gray-900 dark:text-slate-300 hover:text-gray-900 dark:hover:text-white font-medium;

        &:hover {
          td {
            @apply text-gray-800 dark:text-white cursor-pointer;
            span {
              @apply text-gray-800 dark:text-white;
            }
          }
        }

        td {
          @apply border-gray-200 dark:border-gray-600;
        }

        &.active {
          @apply bg-purple-800/90 dark:bg-purple-700/70;

          a {
            @apply hover:text-white;
          }

          td {
            @apply text-white dark:text-white border-purple-400 dark:border-gray-600 #{!important};
            span {
              @apply text-white dark:text-white;
            }
          }
        }
      }
    }
  }
}
