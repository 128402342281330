.ant-picker-input input::placeholder {
  @apply opacity-50;
}

nz-date-picker,
nz-range-picker {
  @apply
  py-0
  w-full
  border
  rounded-lg
  border-gray-200
  dark:border-gray-700
  dark:bg-transparent
  focus:outline-none
  focus:ring-1
  focus-within:ring-1
  appearance-none
  transition
  duration-150
  ease-in-out #{!important};

  &.select-sm {
    .ant-picker-input {
      height: calc(2.25rem - 2px); // h-9 - 2px border
      input,
      .ant-picker-suffix,
      .ant-picker-clear {
        @apply text-xs dark:text-gray-100 #{!important};
      }
    }
  }

  &.select-md {
    .ant-picker-input {
      height: calc(2.75rem - 4px); // h-11 - 2px border
      input,
      .ant-picker-suffix,
      .ant-picker-clear {
        @apply text-sm dark:text-gray-100 #{!important};
      }
    }
  }

  &.select-lg {
    .ant-picker-input {
      height: calc(2.75rem - 2px); // h-11 - 2px border
      input,
      .ant-picker-suffix,
      .ant-picker-clear {
        @apply text-base dark:text-gray-100 #{!important};
      }
    }
  }

  &.ant-picker-focused {
    @apply
    focus:ring-indigo-500
    focus-within:ring-indigo-500
    focus-within:border-indigo-500
    focus:border-indigo-500 #{!important};
  }

  .ant-picker-input {
    .ant-picker-clear {
      @apply
      flex
      items-center
      justify-center
      dark:bg-gray-800
      dark:hover:bg-gray-800;
    }

    input {
      @apply
      h-full
      font-normal
      text-gray-500
      dark:text-gray-100;

      &::placeholder {
        @apply text-gray-600 dark:text-gray-400 placeholder-gray-600 dark:placeholder-gray-400 #{!important};
      }
    }
  }

  &.ant-picker-status-error {
    @apply ring-1 ring-red-500 border-red-500 #{!important};
  }
}

.ant-picker-separator {
  @apply dark:text-white flex items-center;
}

.ant-picker-clear,
.ant-picker-suffix {
  @apply
  flex
  items-center
  justify-center
  dark:text-gray-400
  dark:bg-gray-800
  dark:hover:bg-gray-800;
}
