@import 'ng-zorro-antd/style/index.min.css';
@import 'ng-zorro-antd/dropdown/style/index.min.css';
@import 'ng-zorro-antd/date-picker/style/index.min.css';
@import 'ng-zorro-antd/select/style/index.min.css';
@import 'ng-zorro-antd/table/style/index.min.css';
@import 'ng-zorro-antd/modal/style/index.min.css';
@import 'ng-zorro-antd/rate/style/index.min.css';
@import 'ng-zorro-antd/empty/style/index.min.css';
@import 'ng-zorro-antd/steps/style/index.min.css';
@import 'ng-zorro-antd/result/style/index.min.css';
@import 'ng-zorro-antd/notification/style/index.min.css';
@import 'ng-zorro-antd/descriptions/style/index.min.css';
@import 'ng-zorro-antd/tooltip/style/index.min.css';
@import 'ng-zorro-antd/collapse/style/index.min.css';
@import 'ng-zorro-antd/pagination/style/index.min.css';
@import 'ng-zorro-antd/switch/style/index.min.css';
@import 'ng-zorro-antd/tree/style/index.min.css';
@import 'ng-zorro-antd/tabs/style/index.min.css';
@import 'ng-zorro-antd/checkbox/style/index.min.css';
@import 'ng-zorro-antd/upload/style/index.min.css';
@import 'ng-zorro-antd/radio/style/index.min.css';
@import 'ng-zorro-antd/statistic/style/index.min.css';
@import 'ng-zorro-antd/avatar/style/index.min.css';
@import 'ng-zorro-antd/comment/style/index.min.css';
@import 'ng-zorro-antd/drawer/style/index.min.css';
@import 'ng-zorro-antd/skeleton/style/index.min.css';
@import 'ng-zorro-antd/menu/style/index.min.css';
@import 'ng-zorro-antd/segmented/style/index.min.css';
@import 'ng-zorro-antd/message/style/index.min.css';

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import "swiper/swiper.min.css";

@import 'assets/styles/app';

