.description-list-wrapper {
  @apply py-3 px-3 flex items-center;

  .description-list-label {
    align-items: center;
    display: flex;
    font-weight: 600;
    @apply flex items-center font-semibold text-gray-500 dark:text-slate-400;
  }

  .description-list-content {
    @apply mt-1 flex items-center text-sm text-gray-900 dark:text-slate-400 sm:mt-0;
  }
}

.description-list-action {
  @apply w-7 h-7 flex items-center justify-center bg-gray-50 dark:bg-gray-800 hover:bg-gray-200 dark:hover:bg-gray-700 rounded-lg;

  &.success {
    @apply bg-emerald-50 text-emerald-600 dark:bg-emerald-500/10 dark:text-emerald-400;
  }
}


.ant-descriptions-row>td,
.ant-descriptions-row>th {
  padding-bottom: 12px;
}

nz-descriptions.customer {
  .ant-descriptions-view {
    table {
      .ant-descriptions-item {
        .ant-descriptions-item-container {
          .ant-descriptions-item-label {
            @apply dark:text-white;
          }
          .ant-descriptions-item-content {
            display: flex;
            flex-direction: column;
            @apply px-3 dark:text-slate-400 items-start;
          }
        }
      }
    }
  }
}

nz-descriptions {
  .ant-descriptions-view {
    @apply border-gray-200 dark:border-gray-600;
  }

  .ant-descriptions-view {
    border-radius: 0.25rem;

    table {
      .ant-descriptions-row {
        @apply border-gray-200 dark:border-gray-600;

        .ant-descriptions-item-label {
          background-color: transparent;
          @apply dark:text-white border-gray-200 dark:border-gray-600;
        }
        .ant-descriptions-item-content {
          @apply px-3 dark:text-slate-400 items-center border-gray-200 dark:border-gray-600;
        }
      }

      .ant-descriptions-item {
        .ant-descriptions-item-container {
          .ant-descriptions-item-label {
            //line-height: 2rem;
            //font-weight: 800;
            background-color: transparent;
            @apply dark:text-white border-gray-200 dark:border-gray-600;
          }
          .ant-descriptions-item-content {
            flex: 1;
            @apply px-3 dark:text-slate-400 items-center border-gray-200 dark:border-gray-600;
          }
        }
      }
    }
  }

  &.col-w-30 {
    table {
      .ant-descriptions-item {
        .ant-descriptions-item-container {
          .ant-descriptions-item-label {
            width: 30%;
          }
        }
      }
    }
  }

  &.vertical {
    .ant-descriptions-view {
      table {
        .ant-descriptions-item {
          width: 50%;
          @apply dark:text-white;

          .ant-descriptions-item-container {
            display: inline-flex;
            width: 100%;

            //.ant-descriptions-item-label {
            //  font-weight: 800;
            //}

            .ant-descriptions-item-content {
              flex: 1;
              @apply pl-0;
            }
          }
        }
      }
    }
  }

  &.no-title {
    .ant-descriptions-view {
      table {
        tbody tr:nth-child(odd) {
          display: none;
        }
      }
    }
  }
}
