.dropdown-link {
  @apply mx-1 inline-flex items-center justify-center rounded-lg p-1;

  &.active {
    @apply bg-orange-100 dark:bg-orange-500/10 dark:text-orange-400;
  }

  .dropdown-link-count {
    @apply pr-1 font-semibold;
  }

  .dropdown-link-close {
    @apply justify-center ml-2 w-5 h-5;

    &:hover {
      @apply bg-orange-200 rounded-full;
    }
  }
}
