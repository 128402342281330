nz-tree {
  background-color: transparent !important;
}

nz-tree.customer-documents {
  padding: 20px;
  @apply bg-white dark:bg-gray-900 #{!important};

  .ant-tree-list-holder-inner {
    @apply bg-white dark:bg-gray-900 #{!important};
  }
}

.ant-tree-list-holder-inner {
  @apply dark:bg-gray-800 dark:text-white;
}

.ant-tree-node-content-wrapper:hover {
  @apply dark:bg-gray-700 dark:text-gray-50 #{!important};
}
