.tag {
  @apply
  m-1
  py-0.5
  pl-3
  pr-2
  text-xs
  text-gray-900
  bg-white
  dark:bg-slate-600
  dark:text-slate-50
  font-sans
  inline-flex
  items-center
  rounded-md
  font-semibold
  border
  border-gray-300
  dark:border-gray-700
  whitespace-nowrap;

  &:hover {
    @apply dark:bg-slate-500;
  }
}

.tag-affix {
  @apply h-2 w-2 rounded-full bg-gray-300;
}

.tag-prefix {
  @apply mr-1.5 ml-1.5;
}

.tag-suffix {
  @apply ml-1.5 mr-0 hover:cursor-pointer justify-center;
}
