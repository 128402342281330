.ant-pagination.mini .ant-pagination-item {
  height: 32px !important;
  line-height: 32px !important;
}

nz-pagination {
  min-height: 32px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;

  .ant-pagination-prev,
  .ant-pagination-next {
    position: relative;
    margin: 0;

    button {
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
      border: none !important;
      @apply bg-transparent text-gray-700 dark:text-white #{!important};
    }

    &.ant-pagination-disabled {
      button {
        i {
          @apply text-gray-300 dark:text-gray-600;
        }
      }
    }
  }

  .ant-pagination-item {
    border: none;
    @apply mx-1 bg-transparent;

    a {
      @apply
      h-8
      w-8
      cursor-pointer
      text-gray-500
      dark:text-gray-400
      relative
      rounded
      inline-flex
      items-center
      justify-center
      text-sm
      font-semibold
      select-none;
    }

    &:hover {
      a {
        @apply
        bg-gray-50
        text-indigo-500
        dark:text-gray-100
        dark:bg-gray-600;
      }
    }
  }

  .ant-pagination-item-active {
    a {
      @apply
      text-indigo-600
      bg-indigo-50
      hover:bg-indigo-50
      dark:bg-indigo-600
      dark:text-gray-100;

      &:hover {
        @apply text-indigo-500 bg-indigo-100;
      }
    }
  }

  .ant-pagination-options {
    nz-select {
      nz-select-top-control {
        border: none !important;
        box-shadow: none !important;
      }
    }
  }
}
