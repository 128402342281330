nz-radio-group.radio-group-btn {
  &.sm {
    .ant-radio-button-wrapper {
      @apply h-9 px-3 py-2 text-sm;
    }
  }
}

nz-radio-group.radio-group-btn.sm .ant-radio-button-wrapper {
  @apply dark:border-gray-600;
}

.ant-radio-button-wrapper:not(:first-child)::before {
  background-color: unset;
}

.ant-radio-button-wrapper {
  border-left-width: 1px;
}
