.ant-tooltip-content {
  .ant-tooltip-arrow-content{
    @apply bg-indigo-600 dark:bg-indigo-800 #{!important};
  }
  .ant-tooltip-inner {
    @apply flex items-center px-3 py-1 text-sm font-sans text-white rounded-lg shadow bg-indigo-600 dark:bg-indigo-800;

    p {
      @apply text-gray-200;
    }
  }
}

.ant-tooltip-arrow-content {
  &:before {
    background: red;
    @apply bg-indigo-600 dark:bg-indigo-800 #{!important};
  }
}
