.card {
  @apply rounded-lg flex flex-col;
}

.card-border {
  @apply border border-gray-200 dark:border-gray-700 #{!important};
}

.card-shadow {
  @apply shadow #{!important};
}

.card-header-border {
  @apply border-b border-gray-200 dark:border-gray-600;
}

.card-header-extra {
  @apply flex justify-between items-center;
}

.card-footer {
  @apply rounded-bl-lg rounded-br-lg;
}

.card-footer-border {
  @apply border-t border-gray-200 dark:border-gray-600;
}

.card-footer-right {
  @apply flex justify-end;
}

.card-header,
.card-footer {
  @apply py-3 px-5 flex flex-col;
}

.card-body {
  @apply flex flex-col flex-1;

  &:hover {
    @apply bg-gray-100 dark:bg-gray-700 rounded-lg;
  }
}

.card-gutterless {
  @apply p-0;
}
