html {
  @apply bg-white dark:bg-gray-900 scroll-smooth antialiased;
}

body {
  -webkit-font-variant-ligatures: none;
  font-variant-ligatures: none;
  font-feature-settings: "liga" 0;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @apply text-gray-800 dark:text-gray-400 bg-white dark:bg-gray-900 font-sans antialiased;
}

h1, .h1 {
  @apply text-4xl font-bold text-gray-900 dark:text-gray-100;
}

h2, .h2 {
  @apply text-3xl font-bold text-gray-900 dark:text-gray-100;
}

h3, .h3 {
  @apply text-2xl font-semibold text-gray-900 dark:text-gray-100;
}

h4, .h4 {
  @apply text-xl font-semibold text-gray-900 dark:text-gray-100;
}

h5, .h5 {
  @apply text-lg font-semibold text-gray-900 dark:text-gray-100;
}

h6, .h6 {
  @apply text-base font-semibold text-gray-900 dark:text-gray-100;
}

hr {
  @apply border-gray-200 dark:border-gray-600;
}

p, ul, li {
  @apply text-gray-500 dark:text-gray-400 tracking-[-0.01em] leading-6;
}

.phone-preview {
  p {
    @apply text-white;
  }
}

.for-preview-container {
  p {
    @apply mb-3;
  }
}

em {
  @apply text-gray-300 dark:text-gray-400;
}

a {
  @apply text-orange-600 hover:text-orange-400 dark:text-orange-300 dark:hover:text-orange-200 leading-7 hover:underline hover:underline-offset-4;
}

code {
  @apply text-xs py-0.5 px-1 rounded bg-slate-200 text-gray-700 dark:bg-gray-600 dark:text-gray-300 py-0.5 px-1 rounded;
}

.heading-text {
  @apply text-gray-900 dark:text-gray-100;
}

ul, ol {
  margin: 16px;
  padding-left: 22px;
}

ul {
  list-style-type: circle;
}

ol {
  list-style-type: decimal;
}

small {
  @apply text-gray-500 dark:text-gray-400;
}
