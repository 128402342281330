.nav-item {
  @apply text-gray-700 dark:text-gray-200 dark:hover:text-white hover:text-gray-900 hover:bg-gray-100 dark:hover:bg-gray-700 rounded px-3 py-1.5 flex items-center font-medium;

  &.nav-header {
    @apply flex items-center text-gray-800 dark:text-white hover:bg-slate-100 dark:hover:text-gray-100 dark:hover:bg-slate-700;
  }

  &.nav-header-admin {
    @apply text-gray-600 hover:text-black hover:bg-gray-50 dark:text-gray-100 dark:hover:text-white dark:hover:bg-gray-700;
  }

  &.nav-header-active {
    @apply bg-rose-600/90 text-white hover:bg-rose-500 active:bg-rose-700;
  }

  &.nav-header-admin-active {
    @apply bg-gray-50 text-gray-800 dark:text-gray-100 dark:hover:text-white;
  }

  &.nav-item-vertical {
    @apply text-gray-700 dark:text-gray-200 dark:hover:text-white hover:text-gray-900 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg px-3 py-1 flex items-center #{!important};

    &.sm {
      @apply p-2.5 #{!important};
    }

    &.nav-item-vertical-active {
      @apply bg-gray-50 text-orange-600 dark:bg-gray-700 dark:text-orange-300 #{!important};

      svg-icon, .sub-nav-item {
        @apply text-orange-600 dark:text-orange-300;
      }
    }

    svg-icon {
      @apply text-gray-500 dark:text-gray-400;
    }

    .sub-nav-item {
      @apply text-gray-500 dark:text-gray-400;
    }
  }

  &.nav-item-horizontal {
    @apply mr-2 text-gray-700 dark:text-gray-400 dark:hover:text-white hover:text-gray-900 hover:bg-gray-100 dark:hover:bg-indigo-600 rounded-lg px-3 py-2 inline-flex items-center text-sm font-sans #{!important};

    &.nav-item-horizontal-active {
      @apply bg-gray-100 text-gray-900 dark:bg-indigo-700 dark:text-white #{!important};
    }
  }
}

.nav-collapse-item {
  @apply block px-3 py-2 rounded-lg text-base font-sans;

  &.nav-collapse-header {
    @apply text-red-200 hover:text-red-50 hover:bg-red-500;
  }

  &.nav-collapse-header-active {
    @apply text-white bg-red-500 hover:text-white hover:bg-red-500;
  }
}

.menu-item {
    @apply cursor-pointer font-semibold px-3 rounded-lg flex items-center w-full whitespace-nowrap gap-x-2;

    &.menu-item-light {
        @apply text-gray-600;

        &.menu-item-hoverable {
            @apply hover:text-gray-900 hover:bg-gray-50;
        }

        &.menu-item-active {
            @apply text-gray-900 bg-gray-100 hover:bg-gray-100;
        }
    }

    &.menu-item-dark {
        @apply text-gray-400 dark:text-gray-400;

        &.menu-item-hoverable {
            @apply hover:text-gray-100 hover:bg-gray-700;
        }

        &.menu-item-active {
            @apply text-gray-100 bg-gray-600 hover:bg-gray-600;
        }
    }

    &.menu-item-themed {
        @apply text-gray-100 text-opacity-80;

        &.menu-item-hoverable {
            @apply hover:text-opacity-100 hover:bg-gray-900 hover:bg-opacity-10;
        }

        &.menu-item-active {
            @apply text-gray-100 bg-gray-900 bg-opacity-30 hover:bg-opacity-30;
        }
    }

    &.menu-item-transparent {
        @apply text-gray-600 dark:text-gray-400;

        &.menu-item-hoverable {
            @apply hover:text-gray-900 dark:hover:text-gray-100 hover:bg-gray-900/[.1] dark:hover:bg-white/[.1];
        }

        &.menu-item-active {
            @apply text-gray-900 dark:text-gray-100 bg-gray-900/[.075] dark:bg-white/[.1];
        }
    }
}

.menu-collapse-item {
    @apply cursor-pointer select-none font-semibold px-3 h-10 rounded-lg flex items-center justify-between;

    &.menu-collapse-item-light {
        @apply text-gray-600 hover:text-gray-900 hover:bg-gray-50;
    }

    &.menu-collapse-item-dark {
        @apply text-gray-400 dark:text-gray-400 hover:text-gray-100 dark:hover:text-gray-100 hover:bg-gray-700;
    }

    &.menu-collapse-item-themed {
        @apply text-gray-100 text-opacity-80 hover:text-opacity-100 hover:bg-gray-900 hover:bg-opacity-10;;
    }

    &.menu-collapse-item-transparent {
        @apply text-gray-600 hover:text-gray-900 hover:bg-gray-900/[.1] dark:text-gray-400 dark:hover:text-gray-100 dark:hover:bg-white/[.1];
    }
}

.menu-item-divider {
    @apply border-b border-gray-200 dark:border-gray-600 my-2;
}

.menu-item-disabled {
    @apply cursor-not-allowed opacity-60;
}

.menu-item-link {
    @apply flex w-full h-full items-center;
}

.submenu {
  overflow: hidden;
}

.submenu.expanded {
  max-height: 500px; /* Utilisez une valeur suffisamment grande pour afficher tous les sous-menus */
}
