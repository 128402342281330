.notification-detail {
  p {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  ul {
    list-style: circle;
    padding: 15px;
  }
}

.ant-notification-notice {
  @apply pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white dark:bg-gray-800 shadow-lg ring-1 ring-black ring-opacity-5 font-sans;

  .ant-notification-notice-message {
    @apply font-sans text-gray-900 dark:text-white mb-2;
  }

  .ant-notification-notice-description {
    @apply mt-1 font-sans text-gray-500 dark:text-gray-400;
  }

  .ant-notification-notice-close {
    .ant-notification-notice-close-x {
      @apply font-sans flex items-center text-gray-800 dark:text-white;
    }
  }
}
