.ant-checkbox-wrapper {
  @apply text-gray-800 dark:text-white font-sans;

  .ant-checkbox-checked::after {
    @apply border-0;
  }
  .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    @apply bg-indigo-600 dark:bg-indigo-500;
  }
  .ant-checkbox {
    input[type=radio],
    input[type=checkbox] {
      @apply bg-white dark:bg-gray-800 ring-indigo-600 #{!important};
    }

    [type='checkbox']:focus,
    [type='radio']:focus {
      @apply ring-indigo-600;
    }

    [type='checkbox']:hover,
    [type='radio']:hover {
      @apply ring-indigo-600;
    }

    [type='checkbox']:checked,
    [type='radio']:checked {
      @apply bg-indigo-600 dark:bg-indigo-500 #{!important};
    }

    .ant-checkbox-inner {
      @apply dark:bg-gray-900 dark:border-gray-700 rounded-md;
    }
  }

  .ant-checkbox-checked {
    .ant-checkbox-inner {
      //@apply bg-indigo-600 border-0 rounded;
      background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
      @apply bg-indigo-600 dark:bg-indigo-500 border-transparent;
    }
  }

  &.disabled {
    @apply text-gray-200 bg-gray-100 dark:bg-gray-600 dark:text-gray-400 cursor-not-allowed;
  }
}

.checkbox-label {
    @apply inline-flex cursor-pointer dark:text-gray-200 #{!important};

    &.disabled {
        @apply cursor-not-allowed;
    }
}

.checkbox-day-chooser {
  display: flex;
  justify-content: center;
  align-items: center;

  nz-checkbox-group {
    display: inline-flex;
    justify-content: center;
    align-items: center;

    .ant-checkbox-wrapper:first-child {
      @apply rounded-tl-md rounded-bl-md;
    }

    .ant-checkbox-wrapper:last-child {
      @apply rounded-tr-md rounded-br-md;
    }

    .ant-checkbox-wrapper {
      @apply
      flex
      items-center
      justify-center
      py-2 px-2
      text-sm
      font-semibold
      uppercase
      sm:flex-1
      cursor-pointer
      focus:outline-none
      dark:bg-gray-700
      dark:text-white
      dark:hover:bg-gray-600
      bg-indigo-100
      text-indigo-600;

      .ant-checkbox {
        @apply sr-only;
      }

      &.ant-checkbox-group-item {
        margin-right: 0;
      }

      &.ant-checkbox-wrapper-checked {
        @apply bg-indigo-600 text-white hover:bg-indigo-500;
      }
    }
  }
}
