.filter {
  @apply px-0.5 my-3 py-2 rounded-lg;

  &.horizontal {
    @apply flex items-center gap-3;
  }

  .filter-title {
    @apply text-sm font-medium text-gray-500 dark:text-gray-400;
  }
}
