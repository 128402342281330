.ant-skeleton-input {
  width: 100% !important;
}

.ant-skeleton-button-lg,
.ant-skeleton-button-sm {
  width: 100% !important;
}

.ant-skeleton-button-sm {
  height: 18px !important;
}
