widget-list {
  display: block;
  height: 100%;
}

.widget {
  @apply flex justify-between;

  .widget-title {
    @apply text-sm font-sans text-slate-700 dark:text-white;
  }

  .widget-subtitle {
    @apply text-sm text-gray-500 dark:text-slate-400;
  }

  .widget-button {
    @apply
    flex
    items-center
    justify-center
    h-10 w-10
    rounded-full
    hover:cursor-pointer
    hover:bg-slate-100
    dark:hover:bg-slate-600;
  }
}
