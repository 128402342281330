svg-icon {
  @apply flex items-center #{!important};
}

.icon-btn {
  @apply flex items-center #{!important};

  &.default {
    @apply text-gray-600 dark:text-gray-100;
  }

  &.solid {
    @apply text-white;
  }

  &.twotone {
    @apply text-indigo-600 dark:text-indigo-50;
  }

  &.plain {
    @apply text-gray-600 dark:text-gray-100;
  }
}
