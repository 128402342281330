.ant-select-selection-placeholder {
  @apply opacity-50;
}

nz-select {
  @apply w-full;

  &.ant-select-multiple {
    nz-select-top-control {
      .ant-select-selection-item {
        @apply flex items-center border-0 text-sm bg-gray-100 dark:bg-gray-600 #{!important};

        .ant-select-selection-item-remove {
          @apply flex items-center text-gray-500 dark:text-gray-400;
        }
      }
    }
  }

  &.select-invalid {
    nz-select-top-control {
      @apply ring-1 ring-red-500 border-red-500 #{!important};
    }
  }

  &.select-sm {
    nz-select-top-control {
      @apply text-xs h-9 #{!important};
    }
  }

  &.select-md {
    nz-select-top-control {
      @apply text-sm h-10 #{!important};
    }
  }

  &.select-lg {
    nz-select-top-control {
      @apply text-base h-10 #{!important};
    }
  }

  &.select-group {
    //max-width: 120px;
    nz-select-top-control {
      //border-style: none !important;
      border-right-style: none !important;
      border-bottom-right-radius: unset !important;
      border-top-right-radius: unset !important;
      //@apply rounded;

      &:focus, &:focus-within {
        border-style: inherit !important;
        @apply border #{!important};
      }
    }
  }

  &.ant-select-focused {
    nz-select-top-control {
      box-shadow: none !important;
      @apply
      flex
      items-center
      focus:ring-indigo-500
      focus-within:ring-indigo-500
      focus-within:border-indigo-500
      focus:border-indigo-500 #{!important};
    }
  }

  nz-select-top-control {
    @apply
    flex
    items-center
    border
    border-gray-200
    dark:border-gray-700
    dark:bg-transparent
    rounded-lg
    w-full
    py-1.5 px-3
    focus:outline-none
    focus:ring-1
    focus-within:ring-1
    appearance-none
    transition
    duration-150
    text-gray-500
    dark:text-gray-100
    ease-in-out #{!important};

    .ant-select-selection-item {
      @apply flex items-center #{!important};
    }

    nz-select-search {
      input {
        @apply dark:text-gray-100 dark:placeholder-gray-100 h-full #{!important};

        &::placeholder {
          vertical-align: middle !important;
          @apply text-gray-600 dark:text-gray-400 dark:placeholder-gray-400 #{!important};
        }
      }
    }

    nz-select-placeholder {
      @apply flex items-center text-gray-600 dark:text-gray-400 placeholder-gray-600 #{!important};
    }
  }

  nz-select-arrow {
    &.ant-select-arrow {
      @apply dark:text-white #{!important};
    }
  }

  .ant-select-clear {
    @apply bg-white dark:bg-gray-800 flex items-center #{!important};

    i {
      @apply text-gray-500 dark:text-gray-300 #{!important};
    }
  }
}

nz-option-container {
  @apply rounded-lg bg-white dark:bg-gray-700 shadow-lg py-0 #{!important};

  nz-option-item {
    @apply
    dark:text-gray-400
    dark:hover:text-white
    hover:bg-gray-50
    dark:hover:bg-gray-600 #{!important};

    .ant-select-item-option-content {
      flex: auto;
      height: 30px;
      display: flex;
      align-items: center;
    }

    &.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
      @apply bg-transparent hover:bg-gray-50 dark:hover:bg-gray-600;
    }

    &.ant-select-item-option-selected {
      @apply bg-gray-100 dark:bg-gray-500 dark:text-white #{!important};
    }

    &.ant-select-item-option-disabled {
      @apply opacity-40 dark:opacity-30 hover:bg-transparent cursor-not-allowed #{!important};
    }
  }
}

.custom-footer-option {
  @apply
  bg-gray-50
  border-t
  border-gray-900/5
  dark:border-gray-700
  dark:bg-gray-700;

  .footer-item {
    @apply
    p-2
    flex
    items-center
    justify-start
    text-gray-900
    dark:text-gray-400;
  }
}
