.ant-modal {
  color: inherit !important;
}

.ant-modal-content {
  @apply rounded-xl border border-gray-500 border-opacity-20 ring-0 bg-white dark:bg-gray-800 #{!important};

  .modal-title {
    @apply text-xl dark:text-white;
  }

  .modal-subtitle {
    @apply dark:text-gray-400;
  }

  .modal-container {
    @apply mt-6;
  }
}
