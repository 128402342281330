.time-input-field {
    @apply ring-0 w-6 text-center outline-none bg-transparent;
}

.time-input-wrapper {
    @apply flex items-center h-full flex-row-reverse justify-end;
}

.time-input-separator {
    @apply mx-2;
}
