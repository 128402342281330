nz-text-copy {
  display: flex;
}

[type='number'], [type='email'], [type='password'] {
  -webkit-appearance: none;
  appearance: none;
  background-color: unset;
  border-color: unset;
  border-width: 0;
  border-radius: 0;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
  font-size: unset;
  line-height: unset;
  --tw-shadow: none;

  &:focus {
    border-width: 2px !important;
  }
}
