.ant-steps-navigation .ant-steps-item::before {
  display: none;
}

.ant-steps-navigation .ant-steps-item::after {
  top: calc(50% - 11px);
  margin-top: 0;
  margin-left: 9px;
}

//.ant-steps-navigation .ant-steps-item::before {
//  background: transparent !important;
//}

//.ant-steps-horizontal {
//  margin-top: 50px;
//  margin-bottom: 50px;
//}

.ant-steps-item-container {
  display: inline-flex;
}

.ant-steps-item-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-steps-item-title {
  @apply dark:text-white #{!important};
}

.ant-steps-item-description {
  @apply text-gray-500 dark:text-gray-400 #{!important};
}

.ant-steps-item-wait .ant-steps-item-icon {
  @apply dark:bg-transparent dark:border-gray-400;
}

.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
  @apply dark:text-gray-400;
}

.ant-steps-navigation .ant-steps-item::after {
  @apply dark:border-gray-200;
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  //background: #f43f5e;
  @apply bg-indigo-500;
}

.ant-steps-item-process .ant-steps-item-icon {
  //border-color: #f43f5e;
  @apply border-indigo-500;
}

.ant-steps .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process) > .ant-steps-item-container[role=button]:hover .ant-steps-item-icon {
  //border-color: white;
  @apply border-indigo-500 dark:border-white;
}

.ant-steps .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process) > .ant-steps-item-container[role=button]:hover .ant-steps-item-icon .ant-steps-icon,
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role=button]:hover .ant-steps-item-description, .ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role=button]:hover .ant-steps-item-subtitle,
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role=button]:hover .ant-steps-item-title,
.ant-steps-item-custom.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
  //color: white;
  @apply text-indigo-500 dark:text-white;
}

.ant-steps-item-finish .ant-steps-item-icon {
  //border-color: #f43f5e;
  @apply bg-transparent border-indigo-500 dark:border-indigo-400;
}

.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  //color: #f43f5e;
  @apply text-indigo-500 dark:text-indigo-400;
}

.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after,
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
  //background-color: #f43f5e;
  @apply bg-indigo-500;
}
