.ant-radio-button-wrapper {
  //height: 2.75rem;
  display: inline-flex;
  align-items: center;
  font-family: inherit;
  @apply dark:text-gray-100 dark:bg-gray-700 font-semibold focus:outline-none whitespace-nowrap dark:border-gray-600 #{!important};

  &:hover {
    @apply dark:text-indigo-200;
  }

  &.ant-radio-button-wrapper-checked,
  &.ant-radio-button-wrapper-checked:first-child,
  &.ant-radio-button-wrapper-checked:last-child {
    @apply bg-indigo-50 dark:bg-indigo-500 dark:bg-opacity-20 hover:bg-indigo-100 dark:hover:bg-indigo-500 dark:hover:bg-opacity-30 active:bg-indigo-200 dark:active:bg-indigo-500 dark:active:bg-opacity-40 text-indigo-600 dark:text-indigo-50 border-indigo-600 dark:border-indigo-500 dark:border-opacity-20 #{!important};
  }
}

.ant-radio-button-wrapper:first-child,
.ant-radio-button-wrapper:not(:first-child)::before {
  //@apply bg-gray-200 dark:bg-white/10 #{!important};
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  @apply bg-indigo-600 dark:bg-indigo-800;
}

.ant-radio-button-wrapper:not(:first-child)::before {
  @apply dark:bg-gray-600;
}

.ant-radio-button-wrapper:first-child {
  @apply rounded-tl rounded-bl;
}

.ant-radio-button-wrapper:last-child {
  @apply rounded-tr rounded-br;
}
