.side {
  @apply hidden lg:fixed lg:inset-y-0 lg:flex lg:flex-col dark:bg-gray-900;

  .side-nav {
    @apply
    py-6
    z-40
    flex-col
    flex-auto
    flex-shrink-0
    transition-all
    ease-in-out
    duration-200;

    .side-nav-header {
      position: sticky;
      top: 20px;
      left: 0;
      z-index: 99;
      @apply flex flex-shrink-0 items-center;
    }

    .side-nav-content {
      @apply mt-8 flex flex-1 flex-col overflow-y-auto;
    }
  }
}

.aside-closed {
  width: 0;
  transition: width 0.25s ease-in-out;
  margin-left: auto;
  overflow: hidden;
}

.aside-open {
  width: 50%;
  transition: width 0.25s ease-in-out;
  margin-left: auto;
}
