.fc-event {
  border: unset;

  &.fc-gray {
    .fc-event-main {
      .custom-calendar-event {
        &.event-border {
          @apply border-l-4 border-gray-400;
        }
        .event-dot {
          @apply bg-gray-500;
        }
        .event-text {
          @apply text-gray-800 dark:text-gray-50;
        }
        .event-badge {
          @apply inline-flex items-center rounded-md bg-gray-100 px-1.5 py-0.5 text-xs font-sans text-gray-700 ring-1 ring-inset ring-gray-500/10;
        }
      }
    }
    @apply bg-gray-50 dark:bg-gray-900/80;
  }

  &.fc-blue {
    .fc-event-main {
      .custom-calendar-event {
        &.event-border {
          @apply border-l-4 border-indigo-400;
        }
        .event-dot {
          @apply bg-indigo-500;
        }
        .event-text {
          @apply text-indigo-800 dark:text-indigo-50;
        }
        .event-badge {
          @apply inline-flex items-center rounded-md bg-indigo-100 px-1.5 py-0.5 text-xs font-sans text-indigo-700 ring-1 ring-inset ring-indigo-500/10;
        }
      }
    }
    @apply bg-indigo-50 dark:bg-indigo-900/80;
  }

  &.fc-green {
    .fc-event-main {
      .custom-calendar-event {
        &.event-border {
          @apply border-l-4 border-emerald-400;
        }
        .event-dot {
          @apply bg-emerald-500;
        }
        .event-text {
          @apply text-emerald-800 dark:text-emerald-50;
        }
        .event-badge {
          @apply inline-flex items-center rounded-md bg-emerald-100 px-1.5 py-0.5 text-xs font-sans text-emerald-700 ring-1 ring-inset ring-emerald-600/10;
        }
      }
    }
    @apply bg-emerald-50 dark:bg-emerald-900/80;
  }

  &.fc-red {
    .fc-event-main {
      .custom-calendar-event {
        &.event-border {
          @apply border-l-4 border-rose-400;
        }
        .event-dot {
          @apply bg-rose-500;
        }
        .event-text {
          @apply text-rose-800 dark:text-rose-50;
        }
        .event-badge {
          @apply inline-flex items-center rounded-md bg-rose-100 px-1.5 py-0.5 text-xs font-sans text-rose-700 ring-1 ring-inset ring-rose-600/10;
        }
      }
    }
    @apply bg-rose-50 dark:bg-rose-900/80;
  }
}

.calendar .fc .custom-calendar-event {
  padding-left: 0.375rem;
  padding-right: 0.375rem;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.calendar-sm {
  @apply h-full min-h-[750px];
}

.calendar-inline {
  .fc-header-toolbar {
    .fc-toolbar-chunk:nth-child(2) {
      div {
        display: inline-flex;

        .fc-button-primary {
          display: flex;
          align-items: center;
          justify-content: center;
          @apply bg-transparent border-none #{!important};
        }
      }
    }
  }

  .fc-col-header-cell-cushion {
    @apply capitalize;
  }

  td.fc-day-past,
  td.fc-day-future {
    @apply text-gray-500 dark:text-gray-500 #{!important};
  }
  td.fc-day-today {
    @apply bg-indigo-50 dark:bg-indigo-500 dark:bg-opacity-20 hover:bg-indigo-100 dark:hover:bg-indigo-500 dark:hover:bg-opacity-30 active:bg-indigo-200 dark:active:bg-indigo-500 dark:active:bg-opacity-40 text-indigo-600 dark:text-indigo-50 #{!important};
  }
}

.fc {
  @apply h-full;
}

.fc-theme-standard,
.fc-scrollgrid {
  @apply border-0 #{!important};
}

.fc-theme-standard td,
.fc-theme-standard th {
  @apply border-gray-100 dark:border-gray-600 #{!important};

  &:last-child {
    border-right: 0;
  }
}

.fc .fc-col-header-cell-cushion {
  @apply text-gray-900 dark:text-gray-100 py-3 #{!important};
}

.fc-col-header-cell {
  border-width: 0 0 1px;
}

.fc .fc-daygrid-day-number {
  @apply p-2 #{!important};
}

.fc-view {
  @apply border border-gray-200 dark:border-gray-600 bg-white dark:bg-gray-800 #{!important};
}

.fc-daygrid-dot-event .fc-event-title {
  @apply text-gray-900 dark:text-gray-100 #{!important};
}

.fc .fc-button {
  @apply rounded-md py-2;
}

.fc .fc-toolbar {
  @apply lg:flex-row flex-col items-start lg:items-center gap-2 #{!important};

  .fc-toolbar-chunk {
    .fc-button-group {
      align-items: center !important;
      justify-content: center !important;

      button {
        display: flex !important;
      }
    }

    .fc-toolbar-title {
      text-transform: capitalize !important;
    }
  }
}

.fc .fc-button-primary {
  @apply bg-white border border-gray-300 dark:bg-gray-700 dark:border-gray-700 text-gray-600 dark:text-gray-100 #{!important};

  &:focus {
    @apply shadow-none #{!important};
  }

  &:hover {
    @apply hover:bg-gray-50 dark:hover:bg-gray-600 hover:border-gray-300 dark:hover:border-gray-700 hover:text-gray-900 hover:dark:text-gray-100 #{!important};
  }

  &:disabled {
    @apply cursor-not-allowed bg-white border border-gray-300 dark:bg-gray-700 dark:border-gray-700 text-gray-600 dark:text-gray-100 #{!important};

    &:hover {
      @apply bg-white border border-gray-300 dark:bg-gray-700 dark:border-gray-700 text-gray-600 dark:text-gray-100 #{!important};
    }
  }
}

.fc .fc-button-primary:not(:disabled):active:focus,
.fc .fc-button-primary:not(:disabled).fc-button-active:focus {
  @apply shadow-none #{!important};
}

.fc .fc-button-primary:not(:disabled):active,
.fc .fc-button-primary:not(:disabled).fc-button-active {
  @apply bg-gray-100 border border-gray-300 dark:bg-gray-500 dark:border-gray-500 text-gray-600 dark:text-gray-100 #{!important};
}

.fc {
  .fc-dayGridMonth-button,
  .fc-timeGridDay-button,
  .fc-timeGridWeek-button,
  .fc-today-button {
    @apply px-8 capitalize #{!important};
  }
}

.fc .fc-highlight {
  @apply bg-transparent #{!important};
}

.fc-daygrid-dot-event:hover,
.fc-daygrid-dot-event.fc-event-mirror {
  @apply bg-transparent #{!important};
}

.fc .fc-daygrid-event {
  @apply p-0 rounded-md mb-2 #{!important};
}

.fc-h-event {
  @apply rounded #{!important};
}

//.fc-event-selected,
//.fc-event:focus {
//  @apply shadow-none #{!important};
//
//  &:after {
//    @apply bg-transparent #{!important};
//  }
//}

.fc .fc-cell-shaded,
.fc .fc-day-disabled {
  @apply bg-gray-200 dark:bg-gray-500 #{!important};
}

.fc-daygrid-bg-harness {
  .fc-highlight {
    @apply bg-gray-100 dark:bg-gray-700 #{!important};
  }
}

.fc-daygrid-event {
  @apply text-xs #{!important};
}

.fc .custom-calendar-event {
  //@apply flex items-center min-h-[28px] p-1.5 rounded-md w-full overflow-hidden text-ellipsis #{!important};
}

.fc-v-event {
  @apply border-0 bg-transparent #{!important};
}

.fc .fc-scrollgrid-section table {
  @apply w-full #{!important};
}

.fc .fc-daygrid-body {
  @apply w-full #{!important};
}

.fc-direction-ltr .fc-toolbar > * > :not(:first-child) {
  @apply mx-0 #{!important};
}

.fc-toolbar-chunk {
  @apply flex flex-col sm:flex-row gap-2 w-full sm:w-auto #{!important};
}


/**
 * MULTI MONTH VIEW
 *
 */
.fc-view-harness {
  .fc-multiMonthYear-view {
    @apply border-gray-200 dark:border-white/10 #{!important};

    .fc-multimonth-month {
      .fc-multimonth-header {
        .fc-multimonth-title {
          @apply text-gray-800 dark:text-white font-sans capitalize #{!important};
        }
      }
      .fc-multimonth-daygrid {
        @apply dark:bg-gray-800 #{!important};

        .fc-multimonth-daygrid-table {
          td.fc-day-disabled {
            @apply bg-gray-100 dark:bg-gray-700 #{!important};
          }

          td.fc-day-past,
          td.fc-day-future {
            @apply text-gray-500 dark:text-gray-500 #{!important};

            .fc-daygrid-day-frame {
              .fc-daygrid-day-events {
                bottom: -7px !important;

                .fc-daygrid-day-bottom {
                  a {
                    @apply bg-indigo-50 border-indigo-100 text-indigo-700 dark:text-white dark:border-indigo-700 dark:bg-indigo-700 #{!important};
                  }
                }
              }
            }
          }

          td.fc-day-today {
            @apply bg-indigo-100 dark:bg-indigo-600 dark:text-white #{!important};
          }
        }
      }
    }
  }
}

.fc-timeline-slot-cushion,
.fc-datagrid-cell-main {
  @apply dark:text-white;
}

.available-resource {
  @apply bg-emerald-50 dark:bg-emerald-700 border-l-emerald-600;
}

.fc-timeline-lane {
  border-color: red !important; /* Light gray color for horizontal lines */
}
