.ant-result-title {
  @apply dark:text-white;
}

.ant-result-subtitle {
  @apply dark:text-gray-400;
}

.ant-result-success .ant-result-icon > .anticon {
  @apply text-lime-500;
}

.ant-result-error .ant-result-icon > .anticon {
  @apply text-rose-500;
}

.ant-result-content {
  @apply dark:bg-gray-800;
}
