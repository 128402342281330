.button {
  @apply focus:outline-none whitespace-nowrap w-full flex items-center justify-center font-medium;

  &.radius-round {
    @apply rounded-md;
  }

  &.radius-circle {
    @apply rounded-full;
  }

  &.radius-none {
    @apply rounded-none
  }

  &.in-group {
    @apply rounded-tl-none rounded-bl-none border border-gray-200 dark:border-gray-600 border-l-0 #{!important};
  }

  &.default {
    @apply bg-gray-100 border border-gray-100 dark:bg-gray-700 dark:border-gray-700 hover:bg-gray-200 dark:hover:bg-gray-600 active:bg-gray-100 dark:active:bg-gray-500 dark:active:border-gray-500 text-gray-800 dark:text-gray-100;
  }

  &.solid {
    @apply bg-indigo-600 hover:bg-indigo-500 active:bg-indigo-700 text-white;

    &.danger {
      @apply bg-rose-600 hover:bg-rose-500 active:bg-rose-700 text-white #{!important};
    }

    &.warning {
      @apply bg-orange-500 hover:bg-orange-400 active:bg-orange-600 text-white #{!important};
    }

    &.success {
      @apply bg-lime-500 hover:bg-lime-400 active:bg-lime-600 text-white #{!important};
    }

    &.info {
      @apply bg-sky-500 hover:bg-sky-400 active:bg-sky-600 text-white #{!important};
    }
  }

  &.twotone {
    @apply bg-indigo-50 dark:bg-indigo-500 dark:bg-opacity-20 hover:bg-indigo-100 dark:hover:bg-indigo-500 dark:hover:bg-opacity-30 active:bg-indigo-200 dark:active:bg-indigo-500 dark:active:bg-opacity-40 text-indigo-600 dark:text-indigo-50;

    &.danger {
      @apply bg-rose-50 dark:bg-rose-500 dark:bg-opacity-20 hover:bg-rose-100 dark:hover:bg-rose-500 dark:hover:bg-opacity-30 active:bg-rose-200 dark:active:bg-rose-500 dark:active:bg-opacity-40 text-rose-600 dark:text-rose-50 #{!important};
    }

    &.warning {
      @apply bg-orange-50 dark:bg-orange-500 dark:bg-opacity-20 hover:bg-orange-100 dark:hover:bg-orange-500 dark:hover:bg-opacity-30 active:bg-orange-200 dark:active:bg-orange-500 dark:active:bg-opacity-40 text-orange-600 dark:text-orange-50 #{!important};
    }

    &.success {
      @apply bg-lime-50 dark:bg-lime-500 dark:bg-opacity-20 hover:bg-lime-100 dark:hover:bg-lime-500 dark:hover:bg-opacity-30 active:bg-lime-200 dark:active:bg-lime-500 dark:active:bg-opacity-40 text-emerald-600 dark:text-emerald-50 #{!important};
    }

    &.info {
      @apply bg-sky-50 dark:bg-sky-500 dark:bg-opacity-20 hover:bg-sky-100 dark:hover:bg-sky-500 dark:hover:bg-opacity-30 active:bg-sky-200 dark:active:bg-sky-500 dark:active:bg-opacity-40 text-sky-800 dark:text-sky-50 #{!important};
    }
  }

  &.plain {
    @apply bg-transparent border border-transparent hover:bg-gray-50 dark:hover:bg-gray-600 active:bg-gray-100 dark:active:bg-gray-500 dark:active:border-gray-500 text-gray-800 dark:text-gray-100 font-sans;

    &.menu-icon {
      @apply bg-red-600 p-2 hover:bg-red-600 border-none focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-red-600 #{!important};
    }
  }

  &:disabled {
    @apply opacity-50 cursor-not-allowed;
  }
}
