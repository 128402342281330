.apexcharts-tooltip {
    @apply shadow-lg #{!important};

    &.apexcharts-theme-light {
       @apply border border-gray-200 dark:border-gray-600 bg-white dark:bg-gray-600 #{!important};

        .apexcharts-tooltip-title {
            @apply border-b-0 bg-transparent #{!important};
        }
    }
}

.apexcharts-canvas {
    @apply mx-auto
}

.apexcharts-datalabel-label {
    @apply fill-gray-900 dark:fill-gray-100 text-2xl font-bold #{!important};
}

.apexcharts-datalabel-value {
    @apply fill-gray-500 dark:fill-gray-400 text-base #{!important};
}

.apexcharts-tooltip-title {
    @apply mb-0 #{!important};
}

.apexcharts-gridline {
    @apply stroke-gray-200 dark:stroke-gray-600
}

.apexcharts-text {
    tspan {
        @apply fill-gray-500 dark:fill-gray-400 #{!important};
    }
}

.apexcharts-menu-item {
    @apply text-left
}

.apexcharts-xaxistooltip {
    @apply opacity-0 #{!important};
}

.apexcharts-legend-text {
    @apply text-gray-500 dark:text-gray-400 text-sm font-normal #{!important};
}
