.input {
  @apply
  border
  border-gray-200
  dark:border-gray-700
  dark:bg-transparent
  rounded-lg
  w-full
  py-2
  px-3
  focus:outline-none
  focus:ring-1
  focus-within:ring-1
  focus-within:bg-transparent
  appearance-none
  transition
  duration-150
  text-gray-700
  dark:text-gray-100
  ease-in-out
  font-sans;

  &::placeholder {
    @apply opacity-50;
  }

  &.input-searchable {
    @apply bg-gray-50 dark:bg-gray-800;
  }

  &.borderless {
    @apply border-0;
  }

  &:read-only {
    @apply bg-gray-100 dark:bg-gray-700 text-gray-400 cursor-not-allowed;
  }

  &.input-invalid {
    @apply ring-1 ring-red-500 border-red-500;
  }

  &.input-group {
    @apply rounded-lg focus-within:z-10 #{!important};
  }

  &.input-sm {
    @apply text-xs;
  }

  &.input-lg {
    @apply text-base;
  }

  &.input-md {
    @apply text-sm;

    &:hover {
      @apply bg-gray-50 dark:bg-gray-800 cursor-pointer;
    }
  }

  &.plain {
    min-width: 350px;
    @apply bg-gray-100 border-gray-100 dark:bg-gray-700 dark:border-gray-700;
  }

  &:focus {
    border-width: 1px;
  }
}

.input-field {
  @apply h-10 focus:ring-indigo-500 focus-within:ring-indigo-500 focus-within:border-indigo-500 focus:border-indigo-500;
}

.input-field-sm {
  @apply h-9 focus:ring-indigo-500 focus-within:ring-indigo-500 focus-within:border-indigo-500 focus:border-indigo-500;
}

.input-field-md {
  @apply h-11 focus:ring-indigo-500 focus-within:ring-indigo-500 focus-within:border-indigo-500 focus:border-indigo-500;
}

.input-addon {
  @apply flex items-center px-4 rounded-lg border border-gray-300 bg-gray-50 dark:border-gray-700 dark:bg-gray-700;
}

.input-textarea {
  min-height: 7rem;
}

.input-wrapper {
  @apply relative w-full flex;

  .input-group-clipboard {
    nz-text-copy {
      display: flex;
    }
  }
}

.input-disabled {
  @apply hover:cursor-not-allowed #{!important};

  &::placeholder {
    @apply opacity-70;
  }
}

.input-suffix-start {
  @apply absolute top-2/4 z-50 transform -translate-y-2/4 left-2.5 text-gray-400 dark:text-gray-500;
}

.input-suffix-end {
  @apply absolute top-2/4 transform -translate-y-2/4 right-2.5 flex;
}

.input-error {
  @apply absolute inset-y-0 right-0 m-1 pr-1.5 flex items-center pointer-events-none bg-white dark:bg-gray-800;
}

.input-clear-icon {
  position: absolute;
  top: calc(50% - 0.60rem);
  right: 5px;
  z-index: 99;

  svg-icon {
    @apply text-gray-400 dark:text-gray-500;

    &:hover {
      cursor: pointer;
      @apply text-gray-500 dark:text-gray-400;
    }
  }
}
