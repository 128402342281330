.ant-drawer-content-wrapper {
  min-height: 100vh;
}

.ant-drawer-body {
  @apply bg-white dark:bg-gray-900;
}

.drawer-header {
  @apply flex items-center;

  .drawer-title {
    @apply font-sans dark:text-white line-clamp-1 text-lg font-medium;
  }
}

.drawer-divider {
  @apply border-b border-gray-200 dark:border-gray-600 -mx-6 pt-4 mb-2;
}

.drawer-content {
  @apply pt-6;
}

.drawer-footer {
  position: fixed;
  bottom: 0;
  right: 0;
  margin-left: -24px;
  @apply p-6 bg-gray-50 border-t border-gray-200 dark:bg-gray-800 dark:border-gray-700;
}
