.badge {
  @apply rounded-md px-1.5 py-0.5 inline-flex items-center;
}

.event-dot {
  @apply rounded-full w-2 h-2;
}

.badge-wrapper {
  @apply relative flex;
}

.badge-inner {
  @apply
  absolute z-10
  transform
  top-0
  translate-x-2/4
  -translate-x-2/4
  -translate-y-2/4
  right-0
  left-0;
}
